import React, { FC } from 'react';
import {
    Datagrid,
    Create,
    EditButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    ImageInput,
    ImageField,
    FileInput,
    FileField,
    ArrayInput,
    TextInput,
    NumberInput,
    useTranslate,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleFormIterator,
    Toolbar,
    SaveButton, DateTimeInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { FieldProps, Category } from '../types';

const FileTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.stories.name', { smart_count: 1 })}
        </span>
    ) : null;
};

const FileEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


const StoryCreate = (props: any) => {

    return (
        <Create title={<FileTitle />} onSuccess={() => props.onSave ? props.onSave() : null} {...props}>
            <SimpleForm toolbar={<FileEditToolbar />}>
                <NumberInput label="Volgorde" source="order" />
                <NumberInput label="Locatie nummer" source="number" />
                <DateTimeInput label={"Story - begin"} source={'begin'}/>
                <DateTimeInput label={"Story - eind"} source={'end'}/>
                <TextInput label="Titel NL" source="title" />
                <TextInput label="Titel EN" source="title_en" />
                <RichTextInput lab  el="Story NL" source="description" />
                <RichTextInput label="Story EN" source="description_en" />
                <ArrayInput source="images">
                    <SimpleFormIterator>
                        <TextInput label="Afbeelding titel NL" source="title_nl" />
                        <ImageInput source="images" label="Afbeelding NL" accept="image/*">
                            <ImageField source="image_en" title="image_nl" />
                        </ImageInput>
                        <TextInput label="Afbeelding titel EN" source="title_en" />
                        <ImageInput source="images_en" label="Afbeelding EN" accept="image/*">
                            <ImageField source="image_en" title="image_en" />
                        </ImageInput>
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="videos">
                    <SimpleFormIterator>
                        <TextInput label="Video titel NL" source="videoTitle_nl" />
                        <TextInput type="url" label="Vimeo-link NL" source="vimeoUrl_nl" />
                        <TextInput label="Video titel EN" source="videoTitle_en" />
                        <TextInput type="url" label="Vimeo-link EN" source="vimeoUrl_en" />
                    </SimpleFormIterator>
                </ArrayInput>
                <FileInput source="document" label="QR (PDF) NL" accept="application/pdf">
                    <FileField source="src" title="title" />
                </FileInput>
                <TextInput label="Wachtwoord" source="password" />
            </SimpleForm>
        </Create>
    )
};

export default StoryCreate;
