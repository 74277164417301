import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import Draggable from 'react-draggable';
import './story.scss';
import firebase from "firebase";
import parse from 'html-react-parser';
import Taskbar from "./taskbar";
import $ from "jquery";
import _ from 'underscore';
import ImageGallery from 'react-image-gallery';
import { withTranslation } from 'react-i18next';

class Story extends Component {
    constructor(props) {
        super(props);
        this.storyRef = React.createRef(null);
        this.imageRef = React.createRef(null);
        this.videoRef = React.createRef(null);


        this.state = {
            stories: [],
            currentStory: null,
            selected: "story",
            opened: false,
            document: null,
            groupId: null,
            processing: false
        }
    }

    componentDidMount() {
        const db = firebase.firestore();

        const groupId = localStorage.getItem('groupId');
        if (groupId) {
            this.setState({groupId})
            this.fetchStories(true)
        } else {
            this.props.history.push('/login/empty')
        }

        window.onfocus = (e) => this.fetchStories(false);
    }

    hide(id) {
        let x = document.querySelector(id);
        if(x && x.style) {
            if (x.style.visibility === "hidden") {
                x.style.visibility = "visible";
            } else {
                x.style.visibility = "hidden";
            }
        }
    }

    closeLastStory() {
        this.setState({story: null});
        $("#start-menu").toggle('hide')
    }

    openStory(id) {
        this.setState({currentStory: null, document: null, opened: false});
        this.setState({currentStory: id});
    }

    async fetchStories(initial) {
        const groupId = localStorage.getItem('groupId');
        let stories = [];

        if (initial) {
            this.setState({processing: true});
        }
        console.log(groupId)
        let getStories = firebase.app().functions('europe-west3').httpsCallable('getAvailableStories');
        await getStories({ groupId: groupId })
            .then((result) => {
                // Read result of the Cloud Function.
                stories = result.data;
            });

        if (stories && stories.length > 0) {
            stories.sort((a, b) => (a.begin && b.begin ? a.begin._seconds < b.begin._seconds : 1 ) ? 1 : -1)
            this.setState({stories: stories});

            if (initial) {
                for (let i = stories.length - 1; i >= 0; i--) {
                    const story = stories[i]
                    this.setState({processing: false});
                    if(story.open) {
                        this.setState({currentStory: story.id});
                        break;
                    }
                }
            }
        } else {
            this.setState({stories: []})
            if (initial) {
                this.setState({processing: false});
            }
        }

    }

    handleDrag(window) {
        this.setState({selected: window})
    }

    renderImages(storyImg) {
        const {selected} = this.state;
        const { t, i18n } = this.props;


        if(storyImg.length > 0) {
            const images = storyImg.map((image, index) => {
                const url = (i18n.language === "en-US" ? (image.image_en ? image.image_en : null) : (image.image_nl ? image.image_nl : ""));
                const title = (i18n.language === "en-US" ? (image.title_en ? image.title_en : null) : (image.title_nl ? image.title_nl : ""));
                const description = (i18n.language === "en-US" ? (image.description_en ? image.description_en : null) : (image.description_nl ? image.description_nl : ""));

                return (
                    <Draggable
                        nodeRef={this.imageRef.current}
                        axis="both"
                        handle=".imageHeader"
                        bounds=".storyPage"
                        key={image.url}
                        onStart={() => this.handleDrag(`images${index}`)}
                        defaultPosition={{x: 0, y: 0}}>
                        <div className="imageBox" id={`image${index}`} style={selected === `images${index}` ? {zIndex: 5} : {zIndex: 4}}>
                            <div className="imageHeader">
                                <h3>{title}</h3>
                                <img onClick={() => {
                                    this.hide(`#image${index}`);
                                }} className="closeBtn" src="../close.png"/>
                            </div>
                            <div className="imageBody">
                                <ImageGallery showThumbnails={false} showPlayButton={false} showNav={false} items={[{original: url.src}]}/>
                            </div>
                            <div className={"imageText"}>
                                {parse(description)}
                            </div>
                        </div>
                    </Draggable>
                )
            });

            return images;
        }
    }

    renderVideo(storyVideos) {
        const {selected} = this.state;
        const { t, i18n } = this.props;

        if(storyVideos.length > 0) {
            const videos = storyVideos.map((video, index) => {
                const url = (i18n.language === "en-US" ? (video.vimeoUrl_en ? video.vimeoUrl_en : null) : (video.vimeoUrl_nl ? video.vimeoUrl_nl : ""));
                const title = (i18n.language === "en-US" ? (video.videoTitle_en ? video.videoTitle_en : null) : (video.videoTitle_nl ? video.videoTitle_nl : ""));
                const description = (i18n.language === "en-US" ? (video.description_en ? video.description_en : null) : (video.description_nl ? video.description_nl : ""));

                return (
                    <Draggable
                        nodeRef={this.videoRef.current}
                        axis="both"
                        handle=".videoHeader"
                        bounds=".storyPage"
                        key={url}
                        onStart={() => this.handleDrag(`video${index}`)}
                        defaultPosition={{x: 0, y: 0}}>
                        <div className="videoBox" id={`video${index}`} style={selected === `video${index}` ? {zIndex: 5} : {zIndex: 4}}>
                            <div className="videoHeader">
                                <h3>{title}</h3>
                                <img onClick={() => {
                                    this.hide(`#video${index}`);
                                }} className="closeBtn" src="../close.png"/>
                            </div>
                            <div className="videoBody">
                                <iframe frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                        src={url}/>
                            </div>
                            <div className={"videoText"}>
                                {parse(description)}
                            </div>
                        </div>
                    </Draggable>)
            });

            return videos;
        };
    }

    checkCode = async (event) => {
        const {groupId, currentStory} = this.state;
        event.preventDefault();
        const code = event.target.elements.code.value;

        this.setState({processing: true});
        let checkPassword = firebase.app().functions('europe-west3').httpsCallable('checkPassword');
        await checkPassword({ storyId: currentStory, password: code.toUpperCase(), groupId: groupId })
            .then((result) => {
                // Read result of the Cloud Function.
                const data = result.data;
                this.setState({processing: false});
                if(data.valid) {
                    this.setState({opened: true, document: data.document})
                } else {
                    this.setState({wrong: true})
                }
            });
    };

    renderWelcome() {
        const {selected} = this.state;

        return (
            <Draggable
                axis="both"
                bounds=".storyPage"
                handle=".storyHeader"
                key="welcome"
                onStart={() => this.handleDrag("welcome")}
                defaultPosition={{x: 0, y: 0}}>
                <div style={selected === "welcome" ? {zIndex: 5} : {zIndex: 4}} className="welcomeBox">
                    <div className="storyHeader">
                        <h3>Welkom</h3>
                    </div>
                    <div className="storyBody">
                        <p>Jullie willen natuurlijk de locatie van de kubus achterhalen... dat begrijpen we.<br /><br />
                            Dat zal echter niet zo gemakkelijk worden. De komende twee weken zullen jullie in totaal 4 locatie's moeten achterhalen. Voor elk van deze locaties maken we 4 hints beschikbaar - iedere dag om <b>12:00u</b> vind je hier een nieuwe hint.<br/><br/>
                            Door linksonder op het rondje te drukken kunnen jullie de beschikbare hints vinden.
                            <br/><br/>
                            Voor iedere gevonden locatie kunnen jullie tevens sleutels verdienen. Wanneer jullie maar 1 hint nodig hebben om de locatie te achterhalen verdienen jullie 4 sleutels voor die locatie. Bij 2 hints ontvangen jullie 3 sleutels, 3 hints 2 sleutels en 4 hints 1 sleutel.<br/><br/>
                            <b>In totaal zijn er nu dus al 16 sleutels te verdienen!</b> We wensen jullie veel succes met het terugvinden van de kubus.
                        </p>
                    </div>
                </div>
            </Draggable>
        )
    }

    renderText(title, description, id) {
        const {selected} = this.state;

        if(description) {
            return (
                <Draggable
                    nodeRef={this.storyRef.current}
                    axis="both"
                    bounds=".storyPage"
                    handle=".storyHeader"
                    key={id}
                    onStart={() => this.handleDrag("story")}
                    defaultPosition={{x: 0, y: 0}}>
                    <div ref={this.storyRef.current} style={selected === "story" ? {zIndex: 5} : {zIndex: 4}} className="storyBox">
                        <div className="storyHeader">
                            <h3>{title}</h3>
                            <img onClick={() => {
                                this.hide(".storyBox");
                            }} className="closeBtn" src="../close.png"/>
                        </div>
                        <div className="storyBody">
                            {parse(description)}
                        </div>
                    </div>
                </Draggable>
            )
        }
    }

    renderPassword(number) {
        const {selected} = this.state;

        if(number) {
            return (
                <Draggable
                    nodeRef={this.storyRef.current}
                    axis="both"
                    bounds=".storyPage"
                    handle=".storyHeader"
                    key={"passwordBox"}
                    onStart={() => this.handleDrag("story")}
                    defaultPosition={{x: 0, y: 0}}>
                    <div ref={this.storyRef.current} style={selected === "story" ? {zIndex: 5} : {zIndex: 4}} className="storyBox">
                        <div className="storyHeader">
                            <h3>Heb je de {number}e locatie gevonden?</h3>
                            <img onClick={() => {
                                this.hide(".storyBox");
                            }} className="closeBtn" src="../close.png"/>
                        </div>
                        <div className="storyBody">
                            <p>Vul dan hieronder de code in!</p>
                        </div>
                        <div className="decodeBar">
                            <p>Code</p>
                            <form className="inputForm" onSubmit={this.checkCode}>
                                <input name="code" style={this.state.wrong ? { boxShadow: '0 0 0 1px red'} : {}}
                                       placeholder={"code"}
                                       className="codeInput"/>
                                <button type="submit" className="submitBtn"><span>Controleren</span></button>
                            </form>
                        </div>
                    </div>
                </Draggable>
            )
        } else {
            return null;
        }
    }

    logOut() {
        localStorage.removeItem('currentPage');
        localStorage.removeItem("groupId");
        localStorage.removeItem("groupName");

        this.props.history.push('/login/empty')
    }

    render() {
        const { t, i18n } = this.props;
        const {stories, currentStory, opened, document, processing} = this.state;
        const story = _.findWhere(stories, {id: currentStory});
        const storyImg = (story ? (i18n.language === "en-US" ? (story.images_en ? story.images_en : []) : (story.images ? story.images : [])) : []);
        const videos = (story ? (story.videos ? story.videos : []) : []);
        const title = (story ? (i18n.language === "en-US" ? (story.title_en ? story.title_en : null) : (story.title ? story.title : "")) : "");
        const description = (story ? (i18n.language === "en-US" ? (story.description_en && story.description_en.length > 0 ? story.description_en : null) : (story.description && story.description.length > 0 ? story.description : null)) : null);
        const number = (story ? (story.number ? story.number : null) : null);

        return (
            <div className="storyPage">
                {processing ? (
                    <div className={"loadingContainer"}>
                        <div className={"loader"}>
                            <div className="lds-default">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {opened || (story && story.opened) ? (<div className={"fileOpen"}>
                    <div className={"downloadBox"}>
                        <div className="downloadHeader">
                            <h3>{number}e locatie</h3>
                        </div>
                        <div className="downloadBody">
                            <p>Je hebt toegang gekregen tot de sleutel van de {number}e locatie.</p>
                            <div className={"showCollection"}>
                                <p>Download hier onder de QR-code om deze sleutel tijdens Lockdown Weert te verzilveren.</p>
                                <a href={(document ? document : story.document)} download>
                                    <button className="downloadBtn">
                                        <span>Download sleutel</span></button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>) : null}
                {   this.renderWelcome()
                }
                {
                    this.renderText(title, description, currentStory)
                }
                {
                    this.renderImages(storyImg)
                }
                {
                    this.renderVideo(videos)
                }
                {
                    this.renderPassword(number)
                }
                <Taskbar stories={stories} openStory={(id) => this.openStory(id)} fetchStory={() => this.fetchStories()} currentStory={story} finished={false} description={description} images={storyImg} videos={videos}/>
            </div>
        )
    }

}
;

export default withRouter(withTranslation()(Story));
