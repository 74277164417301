import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import './taskbar.scss';
import firebase from "firebase";
import $ from 'jquery';
import {withTranslation} from "react-i18next";

class Taskbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            time: "",
            startOpen: false,
            lastTime: null,
        }
    }

    componentDidMount() {
        $(document).ready(() => {
            let w = window.innerWidth;

            if(w > 800) {
                $("#start-menu").toggle('hide');
            }

            $("#home-button").click(function () {
                $("#start-menu").toggle('hide');
            });
        });
        setInterval(this.updateClock, 1000);
    }

    hide(id) {
        let x = document.querySelector(id);
        if(x && x.style) {
            if (x.style.visibility === "hidden") {
                x.style.visibility = "visible";
            } else {
                x.style.visibility = "hidden";
            }
        }
    }

    logoutUser = () => {
        localStorage.removeItem('groupId');
        localStorage.removeItem('groupName');
        this.props.history.push('/');
    };

    updateClock = () => {
        let currentTime = new Date();
        let currentHours = currentTime.getHours();
        let currentMinutes = currentTime.getMinutes();

        if (currentMinutes === 0 && this.state.lastTime !== currentHours) {
            this.setState({lastTime: currentHours});
            this.props.fetchStory(false);
        }

        currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;

        let timeOfDay = (currentHours < 12) ? "AM" : "PM";

        currentHours = (currentHours > 12) ? currentHours - 12 : currentHours;
        currentHours = (currentHours === 0) ? 12 : currentHours;

        let currentTimeString = currentHours + ":" + currentMinutes + " " + timeOfDay;
        this.setState({time: currentTimeString})

    }

    openStory = (id) => {
        const { openStory } = this.props;
        $("#start-menu").toggle('hide');

        if(!$(".imageBox").is(':visible')) {
            this.hide(".imageBox")
        }

        if(!$(".storyBox").is(':visible')) {
            this.hide(".storyBox")
        }

        openStory(id);
    };

    renderStoryButtons = (stories, currentStory, i18n) => {
        const {finished} = this.props;
        const dots = '...................................................';

        if (stories) {
            return stories.map(story => {
                const available = story.open || story.opened;

                return(
                    <li key={story.id} onClick={() => {
                        if (available) {
                            this.openStory(story.id);
                        }
                    }} className={"start-menu-item" + (!available ? " disabled" : "")}><i
                        className={"fa fa-file"}></i><span className={!available ? "unavailable" : ""}>{i18n.language === "en-US" ? story.title_en : story.title} {story.opened ? (" - Je hebt deze locatie gevonden!") : story.closed ? (" - Deze locatie is helaas gesloten.") : !story.open ? (" - Wordt beschikbaar op: " + (story.begin && story.begin._seconds ? new Date(story.begin._seconds * 1000 ).toLocaleDateString() + " om " +  new Date(story.begin._seconds * 1000 ).toLocaleTimeString() : "")) : ""}
                    </span>
                        </li>
                )
            })
        }
    };

    render() {
        const {time} = this.state;
        const {currentStory, videos, images, t, i18n, stories, description, videoTitle} = this.props;
        const hasImages =  images.length > 0;
        const hasVideos =  videos.length > 0;

        return (
            <div className="taskbar" id="taskbar">
                <div id="home-button"><i class="far fa-circle"></i></div>
                {currentStory ? <>
                    {description ? <div onClick={() => {
                        this.hide(".storyBox")
                    }} className="storyBtn">{i18n.language === "en-US" ? (currentStory.title_en ? currentStory.title_en : "") : (currentStory.title ? currentStory.title : "")}</div> : null}
                    {hasImages ?
                        images.map((image, index) => {
                            return (
                                <div onClick={() => {
                                    this.hide(`#image${index}`)
                                }} className="storyBtn">{i18n.language === "en-US" ? (image.title_en ? image.title_en : "") : (image.title_nl ? image.title_nl : "")}</div>
                            )
                        }) : null}
                    {hasVideos ?
                        videos.map((video, index) => {
                            return (
                                <div onClick={() => {
                                    this.hide(`#video${index}`)
                                }} className="storyBtn">{i18n.language === "en-US" ? (video.videoTitle_en ? video.videoTitle_en : "") : (video.videoTitle_nl ? video.videoTitle_nl : "")}</div>
                            )
                        }) : null}
                </> : null}
                <div class="taskbar-tools">
                    <div id="time">{time}</div>
                    <div id="wifi"><i class="fa fa-wifi"></i></div>
                    <div id="battery"><i class="fa fa-battery-three-quarters"></i></div>
                    <div id="name">{localStorage.getItem('groupName')}</div>
                </div>

                <div id="start-menu">
                    <div id="settings">
                        <ul>
                            <li onClick={() => this.logoutUser()} className="settings-item"><i className="fa fa-power-off"></i>
                            </li>
                        </ul>
                    </div>

                    <ul>
                        {this.renderStoryButtons(stories, currentStory, i18n)}
                    </ul>
                </div>
            </div>
        );
    }

}
;

export default withRouter(withTranslation()(Taskbar));
