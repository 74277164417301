// @ts-nocheck

import React, {cloneElement, FC, useEffect, useState} from 'react';
import {
    Edit,
    Button,
    SimpleForm,
    TextInput,
    useTranslate,
    SaveButton,
    Toolbar, useDataProvider,
    DateInput,
    DateTimeInput,
    Loading,
    useRefresh
} from 'react-admin';

import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Drawer, TableRow } from '@material-ui/core';
import StoryCreate from '../CustomStories/StoryCreate';

import { FieldProps, Category } from '../types';
import {Route} from "react-router";

const GroupsTitle: FC<FieldProps<Group>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.groups.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

const GroupEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

function useCreateController(props: any) {

}

const GroupEdit = (props: any) => {
    return (
        <Edit undoable={false} title={<GroupsTitle />} {...props}>
            <SimpleForm toolbar={<GroupEditToolbar />} redirect="edit">
                <TextInput label="Naam" source="name" />
                <TextInput fullWidth={true} disabled label="URL" source="url"/>
                <DateTimeInput label="Vervaldatum" source="date" />
                <Button onClick={() => props.push("/groups/" + props.id + "/customcreate")} label={"Custom story toevoegen"} />
            </SimpleForm>
        </Edit>
    )
};


export default connect(
    undefined,
    { push }
)(GroupEdit);
