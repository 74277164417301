import React, {Component} from 'react';
import Draggable from 'react-draggable';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import './login.scss';

class Login extends Component {
    constructor(props) {
        super(props);
        this.loginRef = React.createRef(null);

        this.state = {
            wrong: false,
            code: null,
            processing: false
        }
    }
    componentDidMount () {
        const {code} = this.props.match.params;

        let login = firebase.app().functions('europe-west3').httpsCallable('login');
        if(code && code !== "empty") {
            this.setState({processing: true});
            login({ code })
                .then((result) => {
                    // Read result of the Cloud Function.
                    const data = result.data;
                    if(data) {
                        if(!data.expired) {
                            localStorage.setItem('code', code);
                            localStorage.setItem('groupId', data.id);
                            localStorage.setItem('groupName', data.name);
                            this.setState({code: code})
                            this.redirectToStories();
                        } else {
                            this.setState({processing: false});
                            localStorage.removeItem('groupId');
                            localStorage.removeItem('groupName');
                            this.setState({code: "Verlopen", processing: false})
                        }
                    } else {
                        this.setState({wrong: true, processing: false})
                    }
                });
        }
    }

    redirectToStories = () => {
        const { history } = this.props;
        if(history) history.push(`/stories`)
    };

    checkCode = (event) => {
        event.preventDefault();
        const {code} = this.state;

        let login = firebase.app().functions('europe-west3').httpsCallable('login');
        this.setState({processing: true});
        login({ code: code })
            .then((result) => {
                // Read result of the Cloud Function.
                const data = result.data;
                if(data) {
                    if(!data.expired) {
                        localStorage.setItem('code', code);
                        localStorage.setItem('groupId', data.id);
                        localStorage.setItem('groupName', data.name);
                        this.redirectToStories();
                    } else {
                        localStorage.removeItem('groupId');
                        localStorage.removeItem('groupName');
                        this.setState({code: "Verlopen", processing: false})
                    }
                } else {
                    this.setState({wrong: true, processing: false})
                }
            });
    };

    render() {
        const { t, i18n } = this.props;
        const { code, processing } = this.state;

        return(
            <div className="loginPage">
                {processing ? (
                    <div className={"loadingContainer"}>
                        <div className={"loader"}>
                            <div className="lds-default">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                ) : null}
                    <div ref={this.loginRef.current} className="loginBox">
                        <div className="loginHeader">
                            <h3>Lockdown Weert 2022</h3>
                        </div>
                        <p>Deze website is veilig en alleen toegankelijk met de speciaal verkregen inlogcode.
                            In de veilige omgeving die u na inloggen betreedt, vindt u belangrijke informatie
                            voor uw uiteindelijke missie.  </p>
                        <p><b>{t('login_with_password')}</b></p>
                        <form onSubmit={this.checkCode}>
                            <input value={code} name="password" onChange={(e) => this.setState({code: e.target.value})} style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} className="passwordInput"></input>
                            <button type="submit" className="loginBtn"><span>{t('login')}</span></button>
                        </form>
                    </div>
            </div>
        )
    }
};

export default withRouter(withTranslation()(Login));
